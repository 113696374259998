import { render, staticRenderFns } from "./AreasSpecialitiesTopicsItem.vue?vue&type=template&id=f03286fa&scoped=true"
import script from "./AreasSpecialitiesTopicsItem.vue?vue&type=script&lang=js"
export * from "./AreasSpecialitiesTopicsItem.vue?vue&type=script&lang=js"
import style0 from "./AreasSpecialitiesTopicsItem.vue?vue&type=style&index=0&id=f03286fa&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f03286fa",
  null
  
)

export default component.exports