<template>
  <UnitsFrame>
    <FlashcardHeader @click="$router.push({ name: 'UnitsAreasSpecialities' })">
      {{ activeSpeciality ? activeSpeciality.nombre : "Cargando..." }}
    </FlashcardHeader>
    <AreasSpecialitiesTopics/>
  </UnitsFrame>
</template>

<script>
import FlashcardHeader from '@/components/flashcard/FlashcardHeader'
import UnitsFrame from '@/components/units/UnitsFrame'
import AreasSpecialitiesTopics from '@/components/units/AreasSpecialitiesTopics'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'UnitsAreasSpecialitiesTopics',
  components: {
    UnitsFrame,
    FlashcardHeader,
    AreasSpecialitiesTopics
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapState('flashcard', ['specialitiesByArea']),
    activeSpeciality () {
      if (this.specialitiesByArea.length) {
        const currentSpecialityId = parseInt(this.$route.params.tid)
        return this.specialitiesByArea.find(item => item.id === currentSpecialityId)
      } else {
        return null
      }
    }

  },
  methods: {
    ...mapActions('flashcard', ['fetchSpecialitiesByArea'])
  },
  async created () {
    await this.fetchSpecialitiesByArea(this.$route.params.eid)
  }
}
</script>
