<template>
  <v-container class="ma-0 pa-0">
    <v-row dense flat class="mt-2">
      <v-col
        v-for="(topic, index) in topicsToShow"
        :key="index"
        class="mb-4 flashcard-col"
        :class="{'pl-4': (index + 1) % 3 === 0 && !$vuetify.breakpoint.mobile, 'px-2': (index + 1) % 3 === 2 && !$vuetify.breakpoint.mobile, 'pr-4': (index + 1) % 3 === 1 && !$vuetify.breakpoint.mobile}"
        cols="12"
        sm="6"
        md="4"
      >
        <AreasSpecialitiesTopicsItem
          :topic="topic"
          @click="goToFlashcards(topic)"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import AreasSpecialitiesTopicsItem from '@/components/units/AreasSpecialitiesTopicsItem'

export default {
  name: 'AreasSpecialitiesTopics',
  components: {
    AreasSpecialitiesTopicsItem
  },
  computed: {
    ...mapState('flashcard', ['topicsBySpeciality']),
    topicsWithPendingFlashcards () {
      return this.topicsBySpeciality
    },
    topicsToShow () {
      return [{ especialidad: ' ', icono: '', nombre: 'Todos los temas', id: -1 }, ...this.topicsWithPendingFlashcards]
    }
  },
  methods: {
    ...mapActions('flashcard', ['fetchTopicsBySpeciality', 'fetchAllTopics']),
    goToFlashcards (item) {
      if (item.nombre === 'Todos los temas') {
        const topicIds = this.topicsWithPendingFlashcards.map(topic => topic.id)
        this.$router.push({ name: 'FlashcardSimulator', params: { tipo: 'temas', ids: topicIds.join(',') } })
      } else {
        this.$router.push({ name: 'FlashcardSimulator', params: { tipo: 'temas', ids: item.id } })
      }
    }
  },
  async created () {
    try {
      await this.fetchTopicsBySpeciality(this.$route.params.tid)
    } catch (e) {
      if (e.response.status === 404) {
        await this.$router.push({ name: 'Inicio' })
        return
      }
      await this.$root.$confirm({
        title: 'Ups!',
        message: 'Ha ocurrido un error. Inténtelo de nuevo en unos minutos.',
        acceptMessage: 'Ok'
      })
      this.$router.push({ name: 'Inicio' })
    }
  }
}
</script>
