<template>
  <v-hover v-slot="{ hover }">
    <v-card
      v-bind="$attrs"
      v-on="$listeners"
      class="flashcard-item card"
    >
      <div v-if="topic.especialidad" class="card__content d-flex flex-column" :class="hover || $vuetify.breakpoint.mobile ? 'justify-center': 'justify-space-between'">
        <p class="flashcard__text--body" :class="{'text-center': hover || $vuetify.breakpoint.mobile, 'expand d-flex justify-center align-center': topic.nombre === 'Todos los temas'}" v-text="topic.nombre"></p>
        <div v-if="(!hover && !$vuetify.breakpoint.mobile) && topic.nombre !== 'Todos los temas'">
          <p class="flashcard__text--subtitle ma-0" v-text="topic.especialidad"></p>
          <p class="flashcard__text--subsubtitle ma-0 mt-1" v-text="topic.area"></p>
          <div
            v-if="topic.especialidad!=='Todos los temas'"
            class="card__rent pa-1 mt-2"
            :class="{ 'card__rent--high': rent==='Alta Rentabilidad', 'card__rent--low': rent!=='Alta Rentabilidad' }"
          >
            {{ rent }}
          </div>
        </div>
        <v-slide-x-transition leave-absolute>
          <span v-if="(hover || $vuetify.breakpoint.mobile) && topic.nombre !== 'Todos los temas'" class="card__detail d-flex align-center justify-center mx-auto">
            <p class="card__subtext ma-0">{{ detailItem(topic) }}</p>
          </span>
        </v-slide-x-transition>
      </div>
      <div v-else class="d-flex justify-center align-center mt-4" style="width: 100%; height: 100%;">
        <p class="flashcard__text--body" v-text="topic.nombre"></p>
      </div>
      <div class="card__footer"></div>
    </v-card>
  </v-hover>
</template>

<script>

export default {
  name: 'AreasSpecialitiesTopicsItem',
  data () {
    return {
      flashcardsAvailable: '3435/10483'
    }
  },
  props: {
    topic: {
      type: Object,
      default: function () {
        return {
          area: '',
          especialidad: '',
          id: null,
          nombre: '',
          pendientes: null
        }
      }
    }
  },
  computed: {
    rent () {
      if (this.topic.rentabilidad === 'MEDIA') {
        return 'Media Rentabilidad'
      } else if (this.topic.rentabilidad === 'ALTA') {
        return 'Alta Rentabilidad'
      } else {
        return 'Baja Rentabilidad'
      }
    }
  },
  methods: {
    detailItem (topic) {
      return `${topic.pendientes}/${topic.cantidad_total}`
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  position: relative;
  &__content {
    padding: 30px;
    height: 100% !important;
  }
  &__detail {
    border: 1px solid #E5E5E5;
    width: 96px;
    height: 28px;
    background: #FFFFFF;
    border-radius: 3px;
  }
  &__subtext {
    font-size: 14px;
    line-height: 21px;
  }
  &__footer {
    background: linear-gradient(to right, #1FC1D0 0%, #0583DA 100%);
    height: 9px;
    width: 100%;
    position: absolute;
    bottom: 0;
  }
  &__rent {
    width: 130px;
    border-radius: 5px;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #FFFFFF;
    &--high {
      background: #12A2D6;
    }
    &--middle {
      background: #1FC1D0;
    }
    &--low {
      background: #1FC1D0;
    }
  }
}
.expand {
  height: 100%;
  width: 100%;
}
@media (max-width: 960px) {
  .card {
    &__rent {
      font-size: 12px;
      line-height: 14px;
    }
  }
}
</style>
